*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

#overlay{

    position:fixed;
    width:10%;
    height:50px;
    bottom:0px;
    left:58%;
    padding: 25px; 
    font-size: 1.2em;
    color: rgb(0, 0, 0);
    cursor: pointer; 
    background-color: rgba(255, 255, 255, 0.1);;

}

@media only screen and (min-width: 1366px) and (orientation: landscape) {
    #overlay{

        position:fixed;
        width:5%;
        height:45px;
        bottom:0px;
        left:52%;
        padding: 20px; 
        font-size: 1.2em;
        color: rgb(0, 0, 0);
        cursor: pointer; 
        background-color: rgba(255, 255, 255, 0.1);;
    
    }
}

@media only screen and (min-width: 1024px) and (orientation: portrait) {
    #overlay{

        position:fixed;
        width:6%;
        height:45px;
        bottom:0px;
        left:52%;
        padding: 20px; 
        font-size: 1.2em;
        color: rgb(0, 0, 0);
        cursor: pointer; 
        background-color: rgba(255, 255, 255, 0.1);;
    
    }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
    #overlay{

        position:fixed;
        width:6%;
        height:45px;
        bottom:0px;
        left:52%;
        padding: 20px; 
        font-size: 1.2em;
        color: rgb(0, 0, 0);
        cursor: pointer; 
        background-color: rgba(255, 255, 255, 0.1);;
    
    }

}

@media screen and (max-width: 500px) {
    #overlay{

        position:fixed;
        width:25%;
        height:55px;
        bottom:0px;
        left:20%;
        padding: 20px; 
        font-size: 1.2em;
        color: rgb(0, 0, 0);
        cursor: pointer; 
        background-color: rgba(255, 255, 255, 0.1);;
    
    }

}
